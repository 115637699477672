<template>
  <v-container fluid>
    <v-layout wrap>
      <v-flex xs12>
        <v-card elevation="2">
          <v-card-text>
            <h1>
              <v-icon class="iconTitle">mdi-playlist-check</v-icon> Informes
            </h1>
            <br />
            <v-layout>
              <v-flex xs12>
                <v-card>
                  <v-tabs
                    v-model="tab"
                    background-color="secondary"
                    centered
                    light
                    icons-and-text
                  >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#campoProductores">
                      Informes de productores
                      <v-icon>mdi-account-group</v-icon>
                    </v-tab>
                    <v-tab href="#minmaxVenta">
                      Mínimos y máximos de venta
                      <v-icon>mdi-finance</v-icon>
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item value="campoProductores">
                      <v-card flat>
                        <v-layout>
                          <v-flex xs6>
                            <v-autocomplete
                              v-model="selectClient"
                              :items="
                                [{ id: 0, nombre: 'Todos' }].concat(
                                  this.$store.state.productor
                                )
                              "
                              item-text="nombre"
                              item-value="id"
                              flat
                              hide-no-data
                              label="Filtra por productor o seleccione 'Todos'"
                              prepend-inner-icon="mdi-account-search"
                              outlined
                              @change="informeClientes()"
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs2>
                            <v-menu
                              v-model="menuDateInicial"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  autocomplete="off"
                                  v-model="dateFormattedInicial"
                                  label="Desde"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                @change="changeDateFormatted('fechaInicial');informeClientes()"
                                locale="es"
                                :first-day-of-week="1"
                                v-model="dateInputInicial"
                                outlined
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs2>
                            <v-menu
                              v-model="menuDateFinal"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  autocomplete="off"
                                  v-model="dateFormattedFinal"
                                  label="Hasta"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                @change="changeDateFormatted('fechaFinal');informeClientes()"
                                locale="es"
                                :first-day-of-week="1"
                                v-model="dateInputFinal"
                                outlined
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs3>
                            <v-select
                              label="Tipo de producto"
                              v-model="producto"
                              outlined
                              :items="productos"
                              @change="informeClientes()"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12>
                            <v-data-table
                              :headers="headersResumen"
                              :items="resumen"
                            >
                            </v-data-table>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="minmaxVenta">
                      <v-card flat>
                        <v-layout>
                          <v-flex xs3>
                            <v-select
                              label="Filtrar por producto"
                              v-model="productoVenta"
                              outlined
                              :items="productosVenta"
                              item-text="n"
                              item-value="v"
                              @change="loadCategory();"
                            ></v-select>
                          </v-flex>
                          <v-flex xs3>
                            <v-select v-if="producto!='Frutos secos'"
                              label="Filtrar por categoría"
                              outlined
                              :items="calidades"
                              v-model="calidad"
                              item-text="nombre"
                              item-value="nombre"
                              @change="loadChart()"
                            ></v-select>
                          </v-flex>
                          <v-flex xs2>
                            <v-text-field
                              label="Inicio campaña"
                              v-model="campanaInicio"
                              outlined
                              min=0
                              step=1
                              type="number"
                              style="margin-right: 0;"
                              @change="campanaInicioChange();loadChart()"
                            ></v-text-field>
                          </v-flex>
                          <span style="margin: 1% 1%"> / </span>
                          <v-flex xs2>
                            <v-text-field
                              label="Fin campaña"
                              v-model="campanaFin"
                              outlined
                              min=0
                              step=1
                              type="number"
                              @change="campanaFinChange();loadChart()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs6>
                            <Chart
                              ref="gentePrecio"
                              :dataMax="dataGenteMax"
                              :dataMin="dataGenteMin"
                              :labels="labels"
                              labelMax="Nº de productores dispuestos a vender según precio máximo"
                              labelMin="Nº de productores dispuestos a vender según precio mínimo"
                              canvasId="gentePrecio"
                            />
                          </v-flex>
                          <v-flex xs6>
                            <Chart
                              ref="litrosPrecio"
                              :dataMax="dataLitrosMax"
                              :dataMin="dataLitrosMin"
                              :labels="labels"
                              labelMax="Nº de litros disponibles para vender según precio máximo"
                              labelMin="Nº de litros disponibles para vender según precio mínimo"
                              canvasId="litrosPrecio"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { BASE_URL } from "../components/constants.js";
import * as basics from "../components/basics.js";
import Chart from "../components/Chart.vue";
import genericReload from '../components/genericReload';
export default {
  name: "Informes",
  data: () => ({
    tab: 0,
    selectClient: null,
    menuDateInicial: "",
    dateFormattedInicial: "",
    dateInputInicial: "",
    menuDateFinal: "",
    dateFormattedFinal: "",
    dateInputFinal: "",
    producto: "Todos",
    productos: ['Todos', 'Aceituna', 'Cascara', 'Frutales'],
    headersResumen: [
      {
        text: "Productor",
        align: "start",
        sortable: true,
        value: "productor",
      },
      { text: "Email", value: "email" },
      { text: "Nº fincas", value: "fincas", sortable: true },
      { text: "Nº hectáreas", value: "hectareas", sortable: true },
      { text: "Nº plantas", value: "plantas", sortable: true },
      { text: "Kilos", value: "kilos", sortable: true },
      { text: "Rendimiento medio", value: "rendimiento", sortable: true },
      { text: "Liquidado", value: "liquidado", sortable: true },
      { text: "Precio medio", value: "precio", sortable: true },
    ],
    resumen: [],
    productoVenta: null,
    productosVenta: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
    calidades: [],
    calidad: null,
    campanaInicio: new Date().getFullYear(),
    campanaFin: new Date().getFullYear()+1,
    dataGenteMax: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    dataLitrosMax: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    dataGenteMin: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    dataLitrosMin: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    labels: [
      1.5,
      1.6,
      1.7,
      1.8,
      1.9,
      2,
      2.1,
      2.2,
      2.3,
      2.4,
      2.5,
      2.6,
      2.7,
      2.8,
      2.9,
      3,
      3.1,
      3.2,
      3.3,
      3.4,
      3.5,
      3.6,
      3.7,
      3.8,
      3.9,
      4,
      4.1,
      4.2,
      4.3,
      4.4,
      4.5,
      4.6,
      4.7,
      4.8,
      4.9,
      5,
    ],
  }),
  components: {
    Chart,
  },
  async mounted() {
    this.dateInputInicial = basics.getDate("month", -1);
    this.dateFormattedInicial = basics.formatDate(
      this.dateInputInicial,
      "dd/mm/yyyy"
    );
    this.dateInputFinal = basics.getDate();
    this.dateFormattedFinal = basics.formatDate(
      this.dateInputFinal,
      "dd/mm/yyyy"
    );
    await this.loadChart();
    await this.informeClientes();
  },
  computed: {},
  methods: {
    changeDateFormatted(field) {
      switch (field) {
        case "fechaInicial":
          if (this.dateInputInicial) {
            this.dateFormattedInicial = basics.formatDate(
              this.dateInputInicial,
              "dd/mm/yyyy"
            );
          }
          break;
        case "fechaFinal":
          if (this.dateInputFinal) {
            this.dateFormattedFinal = basics.formatDate(
              this.dateInputFinal,
              "dd/mm/yyyy"
            );
          }
          break;
      }
    },
    async informeClientes(){
      this.resumen = [];
      let productor = null;
      let responseEntradas = null;
      if(this.selectClient != null){
        if(this.selectClient == 0){
          const productores = this.$store.state.productor;
          for(let j=0; j<productores.length; j++){
            let linea = {};
            productor = this.$store.getters.productorById(productores[j].id);
            linea.productor = productor.nombre;
            linea.email = productor.email;
            const responseFincas = await genericReload(this.$store.state.auth, 'finca/?idProductor='+productores[j].id);
            if(this.producto == "Todos"){
              responseEntradas = await genericReload(this.$store.state.auth, 'entrada/?idProductor='+productores[j].id+'&fechaInicio='+this.dateInputInicial+'&fechaFin='+this.dateInputFinal);
            }else{
              responseEntradas = await genericReload(this.$store.state.auth, 'entrada/?idProductor='+productores[j].id+'&tipo='+this.producto+'&fechaInicio='+this.dateInputInicial+'&fechaFin='+this.dateInputFinal);
            }
            const responseLiquidacion = await genericReload(this.$store.state.auth, 'liquidacion/?idProductor='+productores[j].id+'&fechaInicio='+this.dateInputInicial+'&fechaFin='+this.dateInputFinal);
            if (responseFincas.status === 200) {
              let jsonFincas = await responseFincas.json();
              linea.fincas = jsonFincas.body.length;
              linea.hectareas = 0;
              linea.plantas = 0;
              for(let i = 0; i<jsonFincas.body.length; i++){
                linea.hectareas += jsonFincas.body[i].superficie;
                linea.plantas += jsonFincas.body[i].plantas;
              }
            }
            if (responseEntradas.status === 200) {
              let jsonEntradas = await responseEntradas.json();
              linea.kilos = 0;
              linea.rendimiento = 0;
              for(let i = 0; i<jsonEntradas.body.length; i++){
                linea.kilos += jsonEntradas.body[i].kilos;
                linea.rendimiento += jsonEntradas.body[i].rendimiento;
              }
              if(jsonEntradas.body.length > 0){
                linea.rendimiento = Math.round(linea.rendimiento/jsonEntradas.body.length * 100) / 100;
              }
            }
            if (responseLiquidacion.status === 200) {
              let jsonLiquidacion = await responseLiquidacion.json();
              linea.liquidado = 0;
              linea.precio = 0;
              for(let i = 0; i<jsonLiquidacion.body.length; i++){
                linea.liquidado += jsonLiquidacion.body[i].kilos;
                linea.precio += jsonLiquidacion.body[i].precioKilo;
              }
              if(jsonLiquidacion.body.length > 0){
                linea.precio = Math.round(linea.precio/jsonLiquidacion.body.length * 100) / 100;
              }
            }
            this.resumen.push(linea);
          }
        }else{
          productor = this.$store.getters.productorById(this.selectClient);
          let linea = {};
          linea.productor = productor.nombre;
          linea.email = productor.email;
          const responseFincas = await genericReload(this.$store.state.auth, 'finca/?idProductor='+this.selectClient);
          if(this.producto == "Todos"){
            responseEntradas = await genericReload(this.$store.state.auth, 'entrada/?idProductor='+this.selectClient+'&fechaInicio='+this.dateInputInicial+'&fechaFin='+this.dateInputFinal);
          }else{
            responseEntradas = await genericReload(this.$store.state.auth, 'entrada/?idProductor='+this.selectClient+'&tipo='+this.producto+'&fechaInicio='+this.dateInputInicial+'&fechaFin='+this.dateInputFinal);
          }
          const responseLiquidacion = await genericReload(this.$store.state.auth, 'liquidacion/?idProductor='+this.selectClient+'&fechaInicio='+this.dateInputInicial+'&fechaFin='+this.dateInputFinal);
          if (responseFincas.status === 200) {
            let jsonFincas = await responseFincas.json();
            linea.fincas = jsonFincas.body.length;
            linea.hectareas = 0;
            linea.plantas = 0;
            for(let i = 0; i<jsonFincas.body.length; i++){
              linea.hectareas += jsonFincas.body[i].superficie;
              linea.plantas += jsonFincas.body[i].plantas;
            }
          }
          if (responseEntradas.status === 200) {
            let jsonEntradas = await responseEntradas.json();
            linea.kilos = 0;
            linea.rendimiento = 0;
            for(let i = 0; i<jsonEntradas.body.length; i++){
              linea.kilos += jsonEntradas.body[i].kilos;
              linea.rendimiento += jsonEntradas.body[i].rendimiento;
            }
            if(jsonEntradas.body.length > 0){
              linea.rendimiento = Math.round(linea.rendimiento/jsonEntradas.body.length * 100) / 100;
            }
          }
          if (responseLiquidacion.status === 200) {
            let jsonLiquidacion = await responseLiquidacion.json();
            linea.liquidado = 0;
            linea.precio = 0;
            for(let i = 0; i<jsonLiquidacion.body.length; i++){
              linea.liquidado += jsonLiquidacion.body[i].kilos;
              linea.precio += jsonLiquidacion.body[i].precioKilo;
            }
            if(jsonLiquidacion.body.length > 0){
              linea.precio = Math.round(linea.precio/jsonLiquidacion.body.length * 100) / 100;
            }
          }
          this.resumen.push(linea);
        }
      }
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.productoVenta)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
    campanaInicioChange(){
      this.campanaFin = parseInt(this.campanaInicio)+1;
    },
    campanaFinChange(){
      this.campanaInicio = parseInt(this.campanaFin)-1;
    },
    async loadChart() {
      this.dataGenteMax = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ];
      this.dataLitrosMax = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ];
      this.dataGenteMin = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ];
      this.dataLitrosMin = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ];
      if(this.calidad){
        const response = await genericReload(this.$store.state.auth, `alertasPrecio/?producto=${this.productoVenta}&calidad=${this.calidad}&campana=${this.campanaInicio}/${this.campanaFin}`);
        if (response.status === 200) {
          const json = await response.json();
          for (let i = 0; i < json.body.length; i++) {
            let idProductor = json.body[i].idProductor;
            let precioMax = json.body[i].precioMax;
            let precioMin = json.body[i].precioMin;
            this.dataGenteMax[this.labels.indexOf(precioMax)] += 1;
            this.dataGenteMin[this.labels.indexOf(precioMin)] += 1;
            const responseEntrada = await genericReload(this.$store.state.auth, `entrada/?idProductor=${idProductor}`);
            if (responseEntrada.status === 200) {
              const jsonEntrada = await responseEntrada.json();
              let entradas = [];
              if(this.productoVenta == "Aceituna"){
                entradas = jsonEntrada.body.filter((product) => product.calidad === this.calidad);
                for(let j = 0; j<entradas.length; j++){
                  this.dataLitrosMax[this.labels.indexOf(precioMax)] += entradas[j].aceite;
                  this.dataLitrosMin[this.labels.indexOf(precioMin)] += entradas[j].aceite;
                }
              }
              if(this.productoVenta == "Cascara"){
                entradas = jsonEntrada.body;
                for(let j = 0; j<entradas.length; j++){
                  this.dataLitrosMax[this.labels.indexOf(precioMax)] += entradas[j].pipa;
                  this.dataLitrosMin[this.labels.indexOf(precioMin)] += entradas[j].pipa;
                }
              }
              if(this.productoVenta == "Frutales"){
                entradas = jsonEntrada.body.filter((product) => product.categoria === this.calidad);
                for(let j = 0; j<entradas.length; j++){
                  this.dataLitrosMax[this.labels.indexOf(precioMax)] += entradas[j].kilos;
                  this.dataLitrosMin[this.labels.indexOf(precioMin)] += entradas[j].kilos;
                }
              }
            }
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos para completar el gráfico", "Cerrar", "red");
        }
        this.$refs.gentePrecio.render();
        this.$refs.litrosPrecio.render();
      }
    },
  },
};
</script>
<style scoped>
.v-input {
  margin-right: 1em !important;
}
.v-tabs-items {
  padding: 3%;
}
</style>