<template>
  <div class="container">
    <canvas :id="canvasId"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  props: ["dataMax", "dataMin", "labels", "labelMax", "labelMin", "canvasId"],
  data: () => ({}),
  mounted() {
    this.render();
  },
  computed: {
    chartDataMax: function() {
      return this.dataMax;
    },
    chartDataMin: function() {
      return this.dataMin;
    }
  },
  methods: {
    render(){
      const ctx = document.getElementById(this.canvasId);
      new Chart(ctx, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.labelMax,
              data: this.chartDataMax,
              backgroundColor: "rgba(145, 220, 90, 0.2)",
              borderColor: "rgba(145, 220, 90, 1)",
              borderWidth: 2,
            },
            {
              label: this.labelMin,
              data: this.chartDataMin,
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              borderColor: "rgba(255, 0, 0, 1)",
              borderWidth: 2,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: (this.canvasId == "gentePrecio") ? 'Productores' : 'Litros'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Precio'
              }
            }],
          }     
        },
      });
    }
  },
};
</script>
<style scoped>
.container {
  width: 100%;
}
</style>